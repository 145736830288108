import React, { useEffect, useState } from 'react';
import Webcam from 'react-webcam';
import { clearBodyLocks, lock, unlock } from 'tua-body-scroll-lock';

import NumberSpinner from './core/NumberSpinner';
import { ViewportModal } from './core/ViewportModal';
import { View } from './core/layout';

interface BardResponse {
    decoded: boolean;
    barcode: string;
}

function lookup(d: BardResponse) {
    if (!d.decoded) {
        return Promise.reject('Failed to scan');
    }

    return fetch(
        `https://world.openfoodfacts.org/api/v2/product/${d.barcode}`
    ).then((r) => {
        if (r.status === 200) {
            return r.json();
        } else {
            return Promise.reject('Product not found');
        }
    });
}

function callTheBard(imageDataUrl: string): Promise<BardResponse> {
    return fetch('https://bard.cgwyllie.xyz/scan', {
        method: 'QUERY',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({
            base64ImageData: imageDataUrl,
        }),
    }).then((r) => r.json());
}

interface ProductScanned {
    name: string;
    quantityGrams: number;
    energyKcal: number;
}

interface ScannerModalProps {
    open: boolean;
    onProductFound: (p: ProductScanned) => void;
    onCloseRequested: () => void;
}

function ScannerModal({
    open,
    onProductFound,
    onCloseRequested,
}: ScannerModalProps) {
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState<any>(null);
    const [quantityG, setQuantityG] = useState(100);

    const webcamRef = React.useRef<Webcam>(null);
    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current?.getScreenshot();

        if (!imageSrc) {
            return;
        }

        setLoading(true);

        callTheBard(imageSrc)
            .then((v) => lookup(v))
            .then((p) => setProduct(p))
            .catch((e) => alert(e))
            .finally(() => setLoading(false));
    }, [webcamRef]);

    useEffect(() => {
        if (open) {
            lock();
        } else {
            unlock();
        }

        return () => {
            clearBodyLocks();
        };
    }, [open]);

    return (
        <ViewportModal open={open}>
            <View
                style={{
                    background: '#fff',
                }}
            >
                {/* <ul className="step">
                        <li className="step-item">
                            <a href="#" className="tooltip" data-tooltip="Scan">
                                Scan
                            </a>
                        </li>
                        <li className="step-item active">
                            <a
                                href="#"
                                className="tooltip"
                                data-tooltip="Details"
                            >
                                Details
                            </a>
                        </li>
                    </ul> */}
                <Webcam
                    ref={webcamRef}
                    width="100%"
                    height="auto"
                    style={{ aspectRatio: 5 / 2 }}
                    onUserMediaError={() => {
                        // alert('error');
                        console.error('uh-oh');
                    }}
                    screenshotQuality={1}
                    videoConstraints={{
                        width: { min: 1920 },
                        height: { min: 768 },
                        // This ratio is h:w
                        aspectRatio: 2 / 5,
                        facingMode: 'environment',
                    }}
                    imageSmoothing={false}
                />
                <div style={{ flex: 1 }}></div>
                <button
                    className={`btn btn-lg btn-primary ${
                        loading ? 'loading' : ''
                    }`}
                    onClick={capture}
                    disabled={loading}
                >
                    Scan!!
                </button>

                <div className="form-group">
                    <label>Quantity (g)</label>
                    <NumberSpinner
                        disabled={!product}
                        value={quantityG}
                        step={25}
                        onChange={(v) => setQuantityG(v)}
                    />
                </div>

                <button
                    className={`btn btn-lg btn-primary`}
                    disabled={loading || !product}
                    onClick={() => {
                        onProductFound({
                            name:
                                product.product.product_name_en ??
                                product.product.product_name,
                            quantityGrams: quantityG,
                            energyKcal:
                                ((product.product?.nutriments?.[
                                    'energy-kcal_100g'
                                ] ?? -1) /
                                    100) *
                                quantityG,
                        });
                    }}
                >
                    Add!
                </button>
                <button
                    className="btn btn-link"
                    disabled={loading}
                    onClick={onCloseRequested}
                >
                    Cancel
                </button>
            </View>
        </ViewportModal>
    );
}

enum AppState {
    Home,
    Scanning,
}

export function App() {
    const [appState, setAppState] = useState(AppState.Home);

    return (
        <View>
            <button
                className="btn btn-lg btn-primary"
                onClick={() => {
                    setAppState(AppState.Scanning);
                }}
            >
                Add
            </button>
            <ScannerModal
                open={appState === AppState.Scanning}
                onProductFound={(p) => {
                    alert(JSON.stringify(p));
                    setAppState(AppState.Home);
                }}
                onCloseRequested={() => {
                    setAppState(AppState.Home);
                }}
            />
        </View>
    );
}
